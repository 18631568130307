import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/logo.png'

const Navbar = () => {
  return (
    <nav className="bg-white shadow-md fixed top-0 left-0 w-full z-50">
      <div className="container mx-auto px-4 py-2 flex justify-between items-center">
        <div className="text-2xl font-bold text-blue-600">
          <Link to="/"><img src={logo} className='h-10 w-10' alt="" /></Link>
        </div>
        <div className="space-x-6">
          <Link
            to="/"
            className="text-gray-600 hover:text-blue-600 transition duration-300"
          >
            Home
          </Link>
          <Link
            to="/features"
            className="text-gray-600 hover:text-blue-600 transition duration-300"
          >
            Features
          </Link>
          <Link
            to="/about"
            className="text-gray-600 hover:text-blue-600 transition duration-300"
          >
            About
          </Link>
          <Link
            to="/contact"
            className="text-gray-600 hover:text-blue-600 transition duration-300"
          >
            Contact
          </Link>
          <Link
            to="/privacy-policy"
            className="text-gray-600 hover:text-blue-600 transition duration-300"
          >
            Privacy Policy
          </Link>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
