import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="bg-gray-100 min-h-screen p-6">
      <div className="container mx-auto bg-white p-8 rounded-lg shadow-md">
        <h1 className="text-4xl font-bold text-center text-gray-800 mb-8">Privacy Policy for CGJobSchool</h1>
        
        <p><strong>Effective Date:</strong> [Insert Date]</p>

        <section className="mt-6">
          <h2 className="text-2xl font-semibold text-gray-800">Introduction</h2>
          <p className="mt-2">Welcome to CGJobSchool! This privacy policy outlines how we collect, use, and protect your information when you use our app, which provides multiple-choice question (MCQ) tests for students and aspirants preparing for competitive exams.</p>
          <p className="mt-2">By using CGJobSchool, you agree to the collection and use of your information in accordance with this policy.</p>
        </section>

        <section className="mt-6">
          <h2 className="text-2xl font-semibold text-gray-800">Information We Collect</h2>
          <h3 className="text-xl font-semibold text-gray-700 mt-4">Personal Information</h3>
          <p className="mt-2">We may collect the following personal information when you register or use our app:</p>
          <ul className="list-disc list-inside ml-4 mt-2 text-gray-700">
            <li>Name</li>
            <li>Email address</li>
            <li>Phone number</li>
            <li>Educational background</li>
            <li>Exam preferences</li>
          </ul>

          <h3 className="text-xl font-semibold text-gray-700 mt-4">Usage Data</h3>
          <p className="mt-2">We automatically collect certain information about your device and how you use the app, including:</p>
          <ul className="list-disc list-inside ml-4 mt-2 text-gray-700">
            <li>Device type and operating system</li>
            <li>IP address</li>
            <li>App usage data (e.g., features used, time spent on the app)</li>
            <li>Performance data (e.g., scores, progress, and interactions with MCQs)</li>
          </ul>

          <h3 className="text-xl font-semibold text-gray-700 mt-4">Cookies and Tracking Technologies</h3>
          <p className="mt-2">We use cookies and similar tracking technologies to monitor and analyze app usage. This helps us improve the app's functionality and user experience.</p>
        </section>

        <section className="mt-6">
          <h2 className="text-2xl font-semibold text-gray-800">How We Use Your Information</h2>
          <p className="mt-2">We use the collected information for the following purposes:</p>
          <ul className="list-disc list-inside ml-4 mt-2 text-gray-700">
            <li>To provide and maintain our app</li>
            <li>To personalize your experience</li>
            <li>To improve our app based on user feedback and usage patterns</li>
            <li>To communicate with you about updates, new features, and other relevant information</li>
            <li>To analyze app usage and performance for internal purposes</li>
          </ul>
        </section>

        <section className="mt-6">
          <h2 className="text-2xl font-semibold text-gray-800">Sharing Your Information</h2>
          <p className="mt-2">We do not sell, trade, or otherwise transfer your personal information to outside parties except in the following circumstances:</p>
          <ul className="list-disc list-inside ml-4 mt-2 text-gray-700">
            <li><strong>Service Providers:</strong> We may share your information with third-party service providers who assist us in operating our app, conducting our business, or providing services to you, as long as they agree to keep this information confidential.</li>
            <li><strong>Legal Requirements:</strong> We may disclose your information when required by law or in response to valid requests by public authorities (e.g., a court or government agency).</li>
          </ul>
        </section>

        <section className="mt-6">
          <h2 className="text-2xl font-semibold text-gray-800">Security of Your Information</h2>
          <p className="mt-2">We implement a variety of security measures to protect your personal information. However, please note that no method of transmission over the Internet or electronic storage is 100% secure.</p>
        </section>

        <section className="mt-6">
          <h2 className="text-2xl font-semibold text-gray-800">Your Rights</h2>
          <p className="mt-2">You have the following rights regarding your personal information:</p>
          <ul className="list-disc list-inside ml-4 mt-2 text-gray-700">
            <li><strong>Access:</strong> You can request access to the personal information we hold about you.</li>
            <li><strong>Correction:</strong> You can request correction of inaccurate or incomplete information.</li>
            <li><strong>Deletion:</strong> You can request the deletion of your personal information, subject to certain legal obligations.</li>
          </ul>
          <p className="mt-2">To exercise these rights, please contact us at [Insert Contact Information].</p>
        </section>

        <section className="mt-6">
          <h2 className="text-2xl font-semibold text-gray-800">Children's Privacy</h2>
          <p className="mt-2">Our app is not intended for use by children under the age of 13. We do not knowingly collect personal information from children under 13. If you become aware that a child has provided us with personal information, please contact us so that we can take appropriate action.</p>
        </section>

        <section className="mt-6">
          <h2 className="text-2xl font-semibold text-gray-800">Changes to This Privacy Policy</h2>
          <p className="mt-2">We may update our privacy policy from time to time. We will notify you of any changes by posting the new privacy policy on this page and updating the effective date. You are advised to review this privacy policy periodically for any changes.</p>
        </section>

        <section className="mt-6">
          <h2 className="text-2xl font-semibold text-gray-800">Contact Us</h2>
          <p className="mt-2">If you have any questions or concerns about this privacy policy, please contact us at:</p>
          <ul className="list-disc list-inside ml-4 mt-2 text-gray-700">
            <li><strong>Email:</strong> [Insert Email Address]</li>
            <li><strong>Phone:</strong> [Insert Phone Number]</li>
            <li><strong>Address:</strong> [Insert Physical Address]</li>
          </ul>
        </section>

        <p className="mt-6">Thank you for using CGJobSchool. We are committed to protecting your privacy and providing a secure experience for all our users.</p>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
