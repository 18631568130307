import React from 'react';

const HomePage = () => {
  return (
    <div className="bg-gray-100 min-h-screen flex flex-col items-center p-6">
      <header className="w-full bg-blue-600 text-white py-6 shadow-md">
        <div className="container mx-auto text-center">
          <h1 className="text-4xl font-bold">Welcome to CGJobSchool</h1>
          <p className="mt-2">Your Ultimate Companion for Competitive Exam Preparation</p>
        </div>
      </header>
      
      <main className="container mx-auto mt-10">
        <section className="text-center mb-10">
          <h2 className="text-3xl font-semibold text-gray-800">Features</h2>
          <p className="text-gray-600 mt-4">CGJobSchool offers a variety of features to help you excel in your exams.</p>
        </section>
        
        <div className="grid md:grid-cols-3 gap-8">
          <div className="bg-white shadow-md rounded-lg p-6 text-center">
            <h3 className="text-2xl font-semibold text-blue-600">Extensive Question Bank</h3>
            <p className="text-gray-700 mt-4">Access a vast collection of MCQs across various subjects and topics.</p>
          </div>
          
          <div className="bg-white shadow-md rounded-lg p-6 text-center">
            <h3 className="text-2xl font-semibold text-blue-600">Detailed Analytics</h3>
            <p className="text-gray-700 mt-4">Track your performance and identify areas for improvement with our detailed analytics.</p>
          </div>
          
          <div className="bg-white shadow-md rounded-lg p-6 text-center">
            <h3 className="text-2xl font-semibold text-blue-600">Mock Tests</h3>
            <p className="text-gray-700 mt-4">Take mock tests to simulate the exam environment and boost your confidence.</p>
          </div>
        </div>
        
        <section className="mt-10 text-center">
          <h2 className="text-3xl font-semibold text-gray-800">Why Choose CGJobSchool?</h2>
          <p className="text-gray-600 mt-4">CGJobSchool is designed to help students and aspirants improve their skills and achieve their goals. Our platform offers:</p>
          <ul className="list-disc list-inside text-left max-w-lg mx-auto mt-4 text-gray-700">
            <li>Comprehensive question coverage</li>
            <li>Real-time performance tracking</li>
            <li>Regular updates and new questions</li>
            <li>User-friendly interface</li>
            <li>Accessible anytime, anywhere</li>
          </ul>
        </section>
      </main>
      
      <footer className="w-full bg-gray-800 text-white py-4 mt-10">
        <div className="container mx-auto text-center">
          <p>&copy; 2024 CGJobSchool. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
}

export default HomePage;
